.home-page {

    user-select: none;

    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform:translateY(-50%);
        width: 40%;
        max-height: 90%;
    }

    h1 {
        color: #FFFFFF;
        font-size: 56px;
        line-height: 56px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;

        &::before {
            content: '<h1>';
            font-family: 'La Belle Aurore';
            color: #C6DE41;
            font-size: 18px;
            position: absolute;
            margin-top: -40px;
            left: -15px;
            opacity: 0.6;
        }

        &::after {
            content: '</h1>';
            font-family: 'La Belle Aurore';
            color: #C6DE41;
            font-size: 18px;
            position: absolute;
            margin-top: 18px;
            margin-left: 20px;
            animation: fadeIn 1s 1.7s backwards;
            opacity: 0.6;
        }

        img {
            width: 50px;
            margin-left: 20px;
            opacity: 0;
            height: auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;
        }
    }

    h2 {
        color: #C6DE41;
        margin-top: 20px;
        font-weight: 400;
        font-size: 18px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
        display: inline;

        .typewriter-cursor {
            font-size: 32px;
            display: inline-block;
            vertical-align: -5px;
        }
    }

    .flat-button {
        color: #C6DE41;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #C6DE41;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;

        &:hover {
            background: #C6DE41;
            color: #071C21;
        }
    }
}



@media screen and (max-width: 1440px) {
    .home-page {
       .text-zone {
            left: 15%;
            width: 30%;
        }

        h1 {
            font-size: 36px;
            line-height: 36px;

            img {
                width: 35px;
            }
        }

        .logo-container {
            width: 350px;

            svg {
                position: absolute;
                top: auto;
                right: auto;
                bottom: auto;
                left: 0;
                margin: auto;
            }
        }
    }
}


@media screen and (max-width: 1200px) {
    .tags,
    .home-page h1::before,
    .home-page h1::after {
        display: none;
    }

    .home-page {
        .text-zone {
            position: initial;
            width: 414.75px;
            margin: auto;
            transform: none;
            padding: 30px;
            padding-top: 120px;
            box-sizing: border-box;
        }

        h1 {
            font-size: 36px;
            line-height: 36px;

            img {
                width: 35px;
            }
        }

        .flat-button {
            float: none;
            display: block;
            margin: 20px auto 0 auto;
            width: 125px;
        }

        .logo-container {
            position: relative;
            width: 200px;
            height: auto;
            top: 100px;
            right: 0;
            box-sizing: border-box;
            margin: auto;
            left: 0;

            svg {
                position: absolute;
                top: auto;
                right: auto;
                bottom: auto;
                left: 0;
                margin: auto;
            }
        }
    }
}