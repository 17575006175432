.skills-info-card {
    min-height: 400px;
    border-radius: 15px;
    border: 1.5px solid #2D6E7E;
    background: rgba(21, 59, 68, 0.398);
    backdrop-filter: blur(1rem);
    width: 100%;
    font-family: 'Tilt Neon';

    h6 {
        font-size: 24px;
        font-weight: 500;
        margin: 0;
        padding: 20px 20px;
        background: #FFFFFF;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        border-bottom: 1px solid #29616f;
    }
}

.skills-info-content {
    padding: 2rem;
}

.skill-info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
        font-size: 18px;
        font-weight: 500;
    }

    .percentage {
        color: #C6DE41;
    }
}

.skill-progress-bg {
    width: 100%;
    height: 0.5rem;
    background: #59641e;
    border-radius: 0.5rem;
    margin: 1rem 0;
    overflow: hidden;
}

.skill-progress {
    width: 0%;
    height: 0.5rem;
    background: linear-gradient(180deg, #c9d681 0%, #C6DE41 100%);
    border-radius: 0.5rem;
    transition: all 0.5s ease-in-out;
}



@media screen and (max-width: 1200px) {

    .skills-info-card {
        h6 {
            font-size: 22px;
        }
    }
    
    .skill-info {
        p {
            font-size: 16px;
        }
    }
}