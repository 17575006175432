.card {
    border-radius: 10px;
    padding: 12px 16px;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 12px;
    transition: all 0.3s ease-in-out;
    border: 0.1px solid #2D6E7E;
    box-shadow: rgba(45, 110, 126, 0.15) 0px 4px 24px;

    &:hover {
        box-shadow: 0px 0px 20px rgba(7, 28, 33, 0.2);
        transform: translateY(-5px);

        .document img {
            display: flex;
        }

        span {
            overflow: visible;
            -webkit-line-clamp: unset;
        }
    }

    @media only screen and (max-width: 768px) {
        padding: 10px;
        gap: 8px;
        width: 300px;
    }

    .document {
        img {
            display: none;
            height: 70px;
            width: fit-content;
            background-color: #000;
            border-radius: 10px;
            &:hover {
                cursor: pointer;
                opacity: 0.8;
            }
        }
    }

    .description {
        width: 100%;
        font-size: 15px;
        font-weight: 400;
        color: #fff;
        margin-bottom: 10px;
        text-align: justify;

        span {
            overflow: visible;
            display: -webkit-box;
            max-width: 100%;
            -webkit-line-clamp: unset;
            -webkit-box-orient: vertical;
            text-overflow: unset;
        }

        @media only screen and (max-width: 768px) {
            font-size: 12px;
        }
    }

    .top {
        width: 100%;
        display: flex;
        gap: 12px;
        text-align: left;
    }

    .image {
        img {
            height: 50px;
            background-color: #000;
            border-radius: 10px;
            margin-top: 4px;
            @media only screen and (max-width: 768px) {
                height: 40px;
            }
        }
    }

    .body {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .role {
        font-size: 24px;
        font-weight: 600;
        color: #fff;
        @media only screen and (max-width: 768px) {
            font-size: 14px;
        }
    }

    .company {
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        @media only screen and (max-width: 768px) {
            font-size: 12px;
        }
    }

    .date {
        font-size: 12px;
        font-weight: 400;
        color: #fff;
        @media only screen and (max-width: 768px) {
            font-size: 10px;
        }
    }

    .skills {
        width: 100%;
        display: flex;
        gap: 12px;
        margin-top: -10px;
        padding-left: 0;
    }

    .grades {
        width: 100%;
        display: flex;
        gap: 12px;
        margin-top: -10px;
    }

    .item-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }

    .skill {
        font-size: 15px;
        font-weight: 400;
        color: #fff;
        @media only screen and (max-width: 768px) {
            font-size: 12px;
        }
    }

    .grade {
        font-size: 15px;
        font-weight: 400;
        color: #fff;
        @media only screen and (max-width: 768px) {
            font-size: 12px;
        }
    }
}
